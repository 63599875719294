// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


[class*="trackRenderingContainer"]{
    overflow-y: hidden !important;
}


[class\$="tracksContainer"]{
    /*max-height: 500px !important;*/
    /*overflow-y: auto !important;*/
}

[class\$="fieldValue"]{
    width: 60em;
}

.table-row-highlight
{
    background: #6fbfdc !important;
}

.track-label-highlight
{
    background: #6fbfdc40 !important;
}`, "",{"version":3,"sources":["webpack://./src/jbrowse/components/genome-browser.css"],"names":[],"mappings":";;;AAGA;IACI,6BAA6B;AACjC;;;AAGA;IACI,gCAAgC;IAChC,+BAA+B;AACnC;;AAEA;IACI,WAAW;AACf;;AAEA;;IAEI,8BAA8B;AAClC;;AAEA;;IAEI,gCAAgC;AACpC","sourcesContent":["\n\n\n[class*=\"trackRenderingContainer\"]{\n    overflow-y: hidden !important;\n}\n\n\n[class$=\"tracksContainer\"]{\n    /*max-height: 500px !important;*/\n    /*overflow-y: auto !important;*/\n}\n\n[class$=\"fieldValue\"]{\n    width: 60em;\n}\n\n.table-row-highlight\n{\n    background: #6fbfdc !important;\n}\n\n.track-label-highlight\n{\n    background: #6fbfdc40 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
